import React, {Component} from 'react'
import PropTypes          from 'prop-types'
import HorizontalSlider   from '../horizontal-slider'
import cn                 from 'classnames'
import Audio              from './audio'
import PlaylistManager    from './playlist-manager'
import { getFreeSong , getAlbumById} from '../../drawer-items'
import {setCurrentTrack}  from '../../currentDate'
import {getAlbumInfoById, getTrackById, hasAuth} from '../../services/api'
import { loadFreeTracks } from '../../actions/tracks'
import WidgetInfo from '../widget/widgetInfo'

export class Rotator extends Component {
    render() {
        let {amount, offset} = this.props
        offset = offset || 0
        let degrees = ((amount + offset)* 90) - 45
        let style = {transform: `rotate(${degrees}deg)`}
        return (
            <div className="rotator" style={style}>{this.props.children}</div>
        )
    }
}

export function bitrate_to_value(b) {
    let r
    //0 to 800 first half
    if (b <= 800) {
        r = (b / 800) * 0.5
    //800 to 6000 next 1/3
    } else if (b <= 6000) {
        r = ((b - 800) / (6000 - 800)) * (0.6666 / 2) + 0.5
    //6000 to 7000 last 1/6
    } else {
        r = (b - 6800) / (7000 - 6800) * (0.3333 / 2) + (0.5 + (0.6666 / 2))
    }
    return Math.max(0, Math.min(1.0, r))
}

export function fmt_1000(n) {
    if (n < 10) return '00' + n
    if (n < 100) return '0' + n
    return '' + n
}

export function fmt_bitrate(b){
    if (typeof b !== 'number') return b
    if (b < 1000) return b
    return Math.floor(b / 1000)  + ',' + fmt_1000(b % 1000)
}

export class Meter extends Component {
    render(){
        let {bitrate} = this.props
        let angle = bitrate_to_value(bitrate)
        return (
            <div className="meter">
              <div className="arc-mask">
                <Rotator amount={angle} offset={-0.5}><div className="meter-arc" /></Rotator>
              </div>
              <div className="meter-top"/>
              <Rotator amount={angle}><div className="meter-needle" /></Rotator>
              <div className="readout">{fmt_bitrate(bitrate)}</div>
              <div className="meter-bottom" />
            </div>
        )
    }
}


export class PlayerControls extends Component {
    shouldComponentUpdate(newprops){
        return newprops.playing !== this.props.playing ||
               newprops.loading !== this.props.loading ||
               newprops.prevDisabled !== this.props.prevDisabled ||
               newprops.nextDisabled !== this.props.nextDisabled ||
               newprops.disabled !== this.props.disabled
    }
    render(){
        let {playing, loading,  disabled, prevDisabled, nextDisabled, isEmbed, isTrack} = this.props
        let controls = cn('player-controls', {disabled})
        if(isEmbed)controls += ' isEmbed'

        let play = !loading && !playing
        let pause = !loading && playing

        let prevClass = cn('prev-button', {disabled: disabled || prevDisabled})
        let nextClass = cn('next-button', {disabled: disabled || nextDisabled})

        let cx = cn('play-pause-button', {play, pause, loading, disabled})

        return (
            <div className={controls}>
              <div className={prevClass} onClick={this.props.onPrev} />
              <div className={cx} onClick={this.props.onPlayPause} />
              {loading && <div className="loading-indicator-wrapper"><div className="loading-indicator" /></div> }
              <div className={nextClass} onClick={this.props.onNext} />
            </div>
        )
    }
}

export class VolumeControl extends HorizontalSlider {

    render(){
        let pct = this.unscale(this.props.pct)
        let pStyle = { width: `${pct}%` }
        return (
            <div className="volume-control">
              <div className="slider-background" />
              <input className="volume-thumb" style={{ backgroundSize: this.state.volumePct + '%' +'100%'}} type="range" min={0} max={100} value={this.state.volumePct} onChange={((e)=>{this.handleVolumeChange(e)})}/>
            </div>
        )
    }
}

export class ScrubControl extends HorizontalSlider {
    render(){

        let progress = this.unscale(this.props.pct) + '%'
        const { disableScrub = false} = this.props;

        return (
            <div ref="line" className="progress-bar">
              <div className={disableScrub ? "progress innactive" : "progress"} style={{width:progress}}>
                <div className="black-bar" />
                <div ref="thumb" className="thumb"
                     onMouseDown={disableScrub ? null : this.mouseDown} onTouchStart={disableScrub ? null : this.touchStart}>
                  <div className="black-thumb" />
                </div>
              </div>
            </div>
        )
    }
}

export class QualitySwitch extends Component {
    constructor(props) {
        super(props)

        this.state = {quality: Audio.getQuality() ? 'high' : 'low'}

        this.onClick  = this.onClick.bind(this)
        this.setTo320 = this.setTo320.bind(this)

        window.setAudioTo320 = this.setTo320
    }
    setTo320(){
        if (Audio.getQuality()) this.onClick()
    }
    onClick(){
        let quality = this.state.quality === 'high' ? 'low' : 'high'
        this.setState({quality})
        Audio.toggleQuality()
    }
    render() {
        let {quality} = this.state
        let cx = cn("quality-switch", quality)
        return (
            <div className={cx} onClick={this.onClick} />
        )
    }
}

export default class Player extends Component {
    constructor(a, b){
        super(a, b)

        const {pathname = ""} = this.props.location || {}
        const isEmbed = pathname.includes("embed");

        let track = PlaylistManager.currentTrack();
        let {prevDisabled, nextDisabled} = PlaylistManager.prevNextDisabled(isEmbed)

        this.state = {
            track, prevDisabled, nextDisabled,
            volume: 100, playing:false, loading: false,
            state:null, samprate:0, bitrate:0, position:0,
            elapsed:'--:--', remaining:'--:--',view:'initial',
            isTrack:false, isEmbed, playEmbed:false, mainPlayer:true
        }
        this.setVolume        = this.setVolume.bind(this)
        this.playPause        = this.playPause.bind(this)
        this.callback         = this.callback.bind(this)
        this.playlistCallback = this.playlistCallback.bind(this)
        this.loadSOD          = this.loadSOD.bind(this)

        this.scrubDragStart   = this.scrubDragStart.bind(this)
        this.scrubDragMove    = this.scrubDragMove.bind(this)
        this.scrubDragEnd     = this.scrubDragEnd.bind(this)

        this.clickArt   = this.clickArt.bind(this)
        this.clickTrack = this.clickTrack.bind(this)

        this.onKeyPress = this.onKeyPress.bind(this)

        this.pauseIfNeeded  = this.pauseIfNeeded.bind(this)
        this.resumeIfNeeded = this.resumeIfNeeded.bind(this)
        this.toggleView     = this.toggleView.bind(this)
        this.redirection    = this.redirection.bind(this)
        this.playTrackInAlbum = this.playTrackInAlbum.bind(this)

        window.pauseAudioIfNeeded  = this.pauseIfNeeded
        window.resumeAudioIfNeeded = this.resumeIfNeeded


    }
    componentDidMount(){
        const {query:{trackId = false, albumId = false} = {}} = this.props.location || {}
        const {isEmbed} = this.state;
        const id = (trackId || albumId );
        const isTrack = trackId ? true : false ;
        //############## Get track information #######################
       if(isEmbed)this.handleEmbed(isTrack, id);

        if(!this.state.track 
            && !isEmbed
            ){
            let track = getFreeSong();

            this.setState({ track, SOD:true })
        }else if( !isEmbed ){
            PlaylistManager.playTrack(this.state.track)
        }
        Audio.addCallback(this.callback)
        PlaylistManager.addListener(this.playlistCallback)
        document.addEventListener('keypress', this.onKeyPress)
    }
    componentWillUnmount(){
        Audio.removeCallback(this.callback)
        PlaylistManager.removeListener(this.playlistCallback)
        document.removeEventListener('keypress', this.onKeyPress)
    }
    async handleEmbed  (isTrack, id){        
        let track = {};
        let album = {}; 
  
        if(isTrack && id){
            const trackInfo = await getTrackById(id);
            let selectedTrack = trackInfo && trackInfo.track ? [trackInfo.track] :  {};
            //add embed and preview labels
            if(selectedTrack && selectedTrack[0]){
                let preview = selectedTrack[0].hasOwnProperty('preview') ? selectedTrack[0].preview : true;
                selectedTrack[0].preview = preview;
                selectedTrack[0].isEmbed = true;
            }

            loadFreeTracks(selectedTrack)
            track = selectedTrack[0];

        }else if(id){
            const albumInfo = await getAlbumInfoById(id);
            const {album:albumData } = albumInfo 
            
            track = albumData.tracks[0];
            album = albumData;                 
        }
    this.setState({ track, SOD:true , playEmbed:true, isTrack, album, mainPlayer:false})
    }
    loadSOD(){
        const {isEmbed , isTrack, album, SOD, track : stateTrack} = this.state;
        let view = 'initial';
        if(document.readyState){
            //check if user is already logged in 
            if(isEmbed && !hasAuth()){
                view ='login';
            }
            this.setState({SOD:false, playEmbed:false, view, sodLoaded: SOD,repeatSong:false})
            if((isEmbed && !isTrack) || (album && !isEmbed)){
                PlaylistManager.playAlbum(album, true);
                return;
            }
            let track = stateTrack ? stateTrack:  getFreeSong();
            PlaylistManager.playTrack(track)
        }
    }
    onKeyPress(e) {
        let {location} = this.props;
        const currentLocation = location.pathname;
        if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA' || currentLocation.includes("/movie-night") || currentLocation.includes("/video")) return
        if (e.code === "Space") {
            if (this.state.track) this.playPause()
        }
    }
    playlistCallback(info){
        if ((info.state === 'STOPPED' && !this.state.playEmbed && this.state.isTrack) || (this.state.mainPlayer && info.state === 'STOPPED')) {
            const playEmbed = this.state.isEmbed ? true : false;
            this.setState({SOD : playEmbed, playEmbed, isEmbed:playEmbed, playing:false, loading:false, position:0, elapsed:'--:--', remaining:'--:--'})
            return
        }
        if (info.state === 'WILLPLAY') {
            this.setState({loading:true, track:info.track,
                           prevDisabled:info.atStart, nextDisabled: info.atEnd})
        }else if(info.state === 'PAUSED'){
            this.setState({loading:false})
        }else if (info.state === 'LIVEPLAY' && !this.state.playing) {
            this.setState({playing:true, loading:false, track:info.track})
        } else {
            this.setState({track:info.track})
        }
    }
    pauseIfNeeded(){
        if (!this.state.playing) return
        Audio.pause()
        this.setState({playing:false, temporarilyPaused:true})
    }
    resumeIfNeeded(){
        if (!this.state.temporarilyPaused) return
        Audio.play()
        this.setState({playing:true, temporarilyPaused:false})
    }
    playPause(){
        let {playing, loading, position , isEmbed, isTrack} = this.state
        if(isEmbed && position === 1 && isTrack){
            this.loadSOD();
            return;
        }
        if (loading) return
        playing = !playing
        if (playing) Audio.play();
        else         Audio.pause();
        this.setState({playing})
    }
    scrubDragStart(){
        this.setState({scrubbing:true, scrubState:this.state.position * 100})
    }
    scrubDragMove(pct){
        this.setState({scrubState:pct})
    }
    scrubDragEnd(){
        const {isEmbed} = this.state;
        Audio.seek(this.state.scrubState / 100, isEmbed )
        setTimeout(()=>{
            this.setState({scrubbing:false})
        }, 1500)
    }
    setVolume(pct){
        this.setState({volume:pct})
        Audio.setVolume(pct/100)
    }
    callback(playerState) {
        if (playerState.state === 'PAUSED' && this.state.state === 'PAUSED'){
            if (Audio.getIsFirstLoad() && playerState.duration !== "-"){
                this.setState({loading:false,playing: true, elapsed:"00:00"})
                Audio.play()
                Audio.setIsFirstLoad(false);
                return
            }else
            {
                return;
            }
        } 
        if (playerState.state !== this.state.state) {
        }
        if (playerState.state === 'READY') {
            const {isTrack , isEmbed} = this.state; 
            let repeatSong = this.state.repeatSong; 
            let trackReset = this.state.track;
            let album = null;
            if(!repeatSong){
        
                //not track  -> get current playlist 
                repeatSong = this.state.isEmbed  ? false  :  this.state.sodLoaded;
                if( !isTrack && !isEmbed){
                    album  = {tracks:PlaylistManager.currentPlaylist()};
                    trackReset = album && album.tracks[0];
                    repeatSong = true;

                }  
            }
            this.setState({state:playerState.state,track:trackReset,album, repeatSong, playing: false, loading:false,samprate:0, bitrate:0, position:0,
                elapsed:'--:--', remaining:'--:--', elapsed:'--:--', quality })
            return
        }
        let {state, samprate, bitrate, position, elapsed, remaining, quality } = playerState

        if (state === 'LIVEPLAY') {   
            this.setState({playing: true, loading:false, state, samprate, bitrate,position, elapsed, remaining, quality})
           
        } else {
            this.setState({state, samprate, bitrate, position,
                           elapsed, remaining, quality, playing: false })
        }
    }
    clickArt(){ 
        let {track, isEmbed} = this.state
        if(isEmbed){
            this.redirection();
            return;
        }
        if (!track) return
        let {history} = this.props
        let album = track.album || false

        let albumPosition = album && getAlbumById(album.id) || false;
        if(albumPosition) setCurrentTrack(albumPosition);

        let route = `/album?id=${album.id}`;
        if (track && track.performanceLog && track.url) return;

        history.push(route)
    }
    clickTrack(type = false){
        let {track, isEmbed} = this.state
        let {history} = this.props

        if(isEmbed){
            this.redirection(type);
            return;
        }
        if (!track || track.listOnly) return

        let route = track.id && `/info-card?track=${track.id}` || false
        if (track && track.performanceLog && track.url) route = track.url;

        if(route) history.push(`${route}`)
    }
    toggleView(view){
        this.setState({view});
     }
    redirection(type){
        let {track, isTrack, album} = this.state
        let route = false;
        if((track && isTrack) || type === 'track'){
            const {id} = track
            if(id) route = id && `${process.env.SITE_URL}/info-card?track=${id}` || false;
            if(route)window.open(route, '_blank');
        }else if(track) {
            if(album) route = `${process.env.SITE_URL}/album?id=${album.id}`;
            if(route)window.open(route, '_blank');
            return;
        }else{
            return;
        }
       
    }
    playTrackInAlbum(isPlaying, item){
        const {showAreYouStillListeningModal} =  this.props;
        const {album} =  this.state || {};
        if (isPlaying) {
            this.playPause()
        } else {
            this.playPause()
            this.setState({playEmbed:false})

            PlaylistManager._playItemInItems(
              item,
              album.tracks,
              album,
              showAreYouStillListeningModal
            );
        }
    }
    render(){
        let {volume, playing, loading,
             elapsed, remaining, bitrate,
             samprate, position, quality,
             scrubbing, scrubState,
             track,
             prevDisabled, nextDisabled,
             SOD,
             isEmbed,
             playEmbed,
             view,
             isTrack,
             album,
             repeatSong
        } = this.state
        const{ themeAperance = 'light-mode' } = this.props
        let disabled = !track

        
        let title, backgroundImage, performer, orastream, mp3audio, location, country,trackId
        if (track) {
            title = track.title
            backgroundImage = `url('${track.playerImage || track.albumImage}')`
            performer = track.performer
            orastream = track.orastream 
            mp3audio = track.mp3audio
            location = track.location
            country = track.country
            trackId = track.trackId

        } else {
            title = ''
            backgroundImage = ''
            performer = ''
            orastream = false
            mp3audio = false
        }

        let imageStyle = {backgroundImage}
        const previewOnlyTrack = track && track.preview ? track && track.free ? false :  true : false;
        const disableScrub = isEmbed ?  previewOnlyTrack : false ; 

        let experienceRate = '--';
        if(samprate){
            experienceRate = samprate.replace('b/','bit • ');
            experienceRate += `Hz  • ${bitrate} kbps`;
        }

        let sample = samprate ? samprate.replace(/b|k/g,'') : '--'

        if (quality === 10) sample = `FULL ${sample} RESOLUTION`

        let progress = scrubbing ? scrubState : position * 100

        let currBitrate = bitrate_to_value(bitrate)
        let currFill = quality === 10 ? 1.0 : currBitrate

        let onPlayPause = disabled ? undefined : this.playPause
        let playerType = 'middle-layer'
        let playerWrapperType = 'new-player-wrapper'
        if(isEmbed){
            playerWrapperType += isTrack ? ' embed' : ' embed album';
            playerType += isTrack  ? '-embed' : '-embed album';
        }
        if((SOD && (track && track.free)) || playEmbed || repeatSong)onPlayPause = this.loadSOD
      

        if (!Audio.getQuality()) currFill = quality / 10.0;

        let bwbgStyle = {height:currFill * 100 + '%'}
        return (
          <div className={playerWrapperType}>
            <div className={`new-player ${themeAperance}`}>
              <div className={`currently-playing ${isEmbed}`}>
                {!isEmbed && (
                  <div className="bandwidth-background-wrapper">
                    <div className="bandwidth-background" style={bwbgStyle} />
                  </div>
                )}
                <div className="info-wrapper" onClick={()=>this.clickTrack('track')}>
                  <div className="track-title">{`${title}${
                    location ? ` - ${location}, ${country}` : ""
                  }`}</div>
                  <div className="track-performer">{performer}</div>
                  <div className="time-indicators">
                    <div className="left">{elapsed}</div>
                    {!isEmbed && <div className="center">{sample}</div>}
                    <div className="right">{remaining}</div>
                  </div>
                </div>
                <div
                  className="album-artwork"
                  onClick={this.clickArt}
                  style={imageStyle}
                />
                <ScrubControl
                  dragStart={this.scrubDragStart}
                  onScroll={this.scrubDragMove}
                  dragEnd={this.scrubDragEnd}
                  pct={progress}
                  disableScrub={disableScrub}
                />
              </div>
              <div className={playerType} />
              {!isEmbed && <Meter bitrate={bitrate} />}
              <PlayerControls
                disabled={disabled}
                prevDisabled={prevDisabled}
                nextDisabled={nextDisabled}
                isEmbed={isEmbed}
                loading={loading}
                playing={playing}
                isTrack={isTrack}
                onPlayPause={onPlayPause}
                onPrev={disabled || prevDisabled ? undefined : Audio.prev}
                onNext={disabled || nextDisabled ? undefined : Audio.next}
              />
              {!isEmbed && <QualitySwitch />}
            </div>
            {!isEmbed && (
              <VolumeControl onScroll={this.setVolume} pct={volume} />
            )}
            {/* width="720" , height="320" */}
            {isEmbed && (
              <WidgetInfo
                bitrate={experienceRate}
                playerState={this.state.state}
                loading={loading}
                view={view}
                toggleView={this.toggleView}
                redirection={this.redirection}
                isTrack = {isTrack}
                trackId={trackId}
                album={album}
                playTrackInAlbum={this.playTrackInAlbum}
              />
            )}
          </div>
        );
    }
}

Player.contextTypes = {
    router: PropTypes.object.isRequired
}
